.footer {
  background: #000;
  padding: 2em 0;
  text-align: center;
  a:hover {
    color: $text-color2;
  }
  &-about {
    @include fz(13px);
    &-logo {
      margin: 0 auto 1em;
    }
    &-title {
      @include fz(38,24);
      font-weight: 500;
      line-height: 1.25;
      margin: 0 0 0.75em;
    }
    &-desc {
      line-height: (35 / 24);
    }
    &-info {
      display: inline-block;
      color: #fff !important;
      font-style: normal;
      font-weight: 300;
      margin: 1.5em 0 2.5em;
      text-align: left;
      max-width: 17em;
      .item {
        line-height: (35 / 24);
        margin-bottom: 0.5em;
        @include clearfix;
      }
      .label {
        float: left;
      }
      .txt {
        margin-left: 3em;
      }
    }
    .company1 {
      color: #f2d0aa;
      .footer-about-logo {
        max-width: 10em;
      }
    }
    .company2 {
      color: #e4bd51;
      .footer-about-logo {
        max-width: 18em;
      }
    }
  }
  &-title {
    @include fz(36,30);
    font-weight: 500;
    line-height: 1.3;
    margin: 2em 0 1em;
  }
  &-sitemap {
    &-list {
      line-height: 1.5;
      li {
        margin-bottom: 0.75em;
      }
    }
  }
  &-contact {
    &-txt {
      margin-bottom: 1em;
    }
  }
  &-follow {
    text-align: center;
    a {
      width: 75 / 30 + 0em;
      height: 75 / 30 + 0em;
      opacity: 0.8;
      margin: 0 1.75em 1em;
      &:hover {
        opacity: 1;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .footer {
    text-align: left;
    &-about {
      float: left;
      width: 50%;
      &-logo {
        margin-left: 0;
      }
      &-info {
        max-width: none;
      }
    }
    &-sitemap,
    &-contact {
      margin-left: 58%;
    }
  }
}
@media (min-width: $screen-md-min) {
  .footer {
    &-about {
      width: 40%;
    }
    &-sitemap,
    &-contact {
      float: left;
      margin-left: 10%;
    }
    &-sitemap {
      width: 15%;
    }
    &-contact {
      margin-left: 5%;
    }
  }
}
@media (min-width: $screen-lg-min) {
  .footer {
    @include fz(20px);
  }
}
@media (min-width: $screen-xl-min) {
}