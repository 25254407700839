.wrapper {
  padding-top: 65px;
}
.container {
  margin: 0 auto;
  padding: 0 25px;
  max-width: 1280px;
}
.main {
  .container {
    width: 98%;
  }
}
.follow {
  a {
    @include txt-hide;
    background: url('../img/follow.svg') no-repeat;
    background-size: 200% auto;
    transition: none;
    width: 35px;
    height: 35px;
    &.facebook {
      background-position: 0 0;
      &:hover {
        background-position: 0 100%;
      }
    }
    &.twitter {
      background-position: 100% 0;
      &:hover {
        background-position: 100% 100%;
      }
    }
  }
}
.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > .col {
    padding: 0 25px;
    flex: 100% 0 0;
    &.p-0 {
      padding: 0;
    }
  }
  &.xs-x2 {
    > .col {
      flex: 50% 0 0;
    }
  }
}
.icon-crown {
  background: url('../img/sect7_icon.png') no-repeat;
  background-size: contain;
  display: inline-block;
  width: 0.9em;
  height: 0.6em;
}

@media (min-width: $screen-xs-min) {
  .main {
  }
}
@media (min-width: $screen-sm-min) {
  .wrapper {
    padding-top: 84px;
  }
  .main {
    .container {
      width: 90%;
    }
  }
  .flex-row {
    &.sm-x2 {
      > .col {
        flex: 50% 0 0;
      }
    }
    &.sm-x4 {
      > .col {
        flex: 25% 0 0;
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .flex-row {
    &.md-x2 {
      > .col {
        flex: 50% 0 0;
      }
    }
    &.md-x3 {
      > .col {
        flex: 33.33% 0 0;
      }
    }
  }
}
@media (min-width: $screen-lg-min) {
  .wrapper {
    padding-top: 136px;
  }
}
@media (min-width: $screen-xl-min) {
}
