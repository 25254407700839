.anime {
  visibility: hidden;
  &.anime-run {
    visibility: visible;
  }
}
.sect0 {
  .anime-run {
    &.txt {
      @include fadeInRight($duration: 0.5s, $delay: 0.3s);
    }
    &.pic {
      @include fadeInLeft($duration: 0.5s, $delay: 0.3s);
    }
  }
}
.sect5 {
  .anime-run {
    &.txt {
      @include fadeIn($duration: 0.5s, $delay: 0.1s);
    }
    &.pic {
      @include fadeInDown($duration: 0.5s, $delay: 0.3s);
    }
  }
}
.sect6 {
  .anime-run {
    &.txt {
      @include fadeInRight($duration: 0.5s, $delay: 0.1s);
    }
    &.pic {
      @include fadeInRight($duration: 0.5s, $delay: 0.3s);
    }
  }
}
.sect1,
.sect3 {
  .col.anime-run {
    @include fadeInUp($duration: 0.5s);
    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        animation-delay: #{$i * 0.2 - 0.1}s;
      }
    }
  }
}
.sect2 {
  .container.anime-run {
    @include fadeIn($duration: 1s);
  }
}
.sect9 {
  .container.anime-run {
    @include zoomIn($duration: 1s);
  }
}
.sect4,
.sect8 {
  .col.anime-run {
    @include fadeIn($duration: 0.5s);
    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        animation-delay: #{$i * 0.2 - 0.1}s;
      }
    }
  }
}
.sect7 {
  .table-outer.anime-run {
    @include fadeInUp($duration: 1s);
  }
}

@media screen and (min-width: $screen-sm-min)  {
}
