.bt {
  @include btn-reset;
  &-style1 {
    background: $brand-secondary;
    background: linear-gradient(to right,  $brand-secondary 0%,$brand-secondary-dark 80%,$brand-secondary-dark2 100%);
    border-radius: 0.6em;
    padding: 0.2em;
    box-shadow: 0.06em 0.08em 0.1em rgba(#000,0.75);
    position: relative;
    top: 0;
    left: 0;
    transition: 0.3s;
    span {
      display: block;
      background: $brand-secondary-dark2;
      background: linear-gradient(to right,  $brand-secondary-dark2 0%,$brand-secondary-dark 20%,$brand-secondary 100%);
      border-radius: 0.4em;
      padding: 0 0.4em;
      text-shadow: 0.088em 0.088em 0.264em #6b4e39;
    }
    &:hover {
      top: 0.1em;
      left: 0.1em;
      box-shadow: 0 0 0.2em rgba(#000,0.75);
    }
  }
}

@media (min-width: $screen-sm-min) {
  .bt {
  }
}
@media (min-width: $screen-md-min) {

}
@media (min-width: $screen-lg-min) {
}
