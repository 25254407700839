.sect {
  padding: 3em 0 1.5em;
  text-align: center;
  &-title {
    @include fz(24px);
    font-weight: 500;
    line-height: 1.35;
    margin: 0 0 1em;
    span {
      display: inline-block;
    }
  }
}
.sect0 {
  padding: 0;
  background: url('../img/banner_bg.jpg') no-repeat 0 0;
  background-size: cover;
  .txt {
    padding: 2em 0;
    @include fz(20px);
  }
  .title {
    @include fz(80,50);
    font-weight: 500;
    line-height: 1.2;
    background: url('../img/banner_txt_deco.png') no-repeat 60% 100%;
    background-size: 100% 3em;
    padding: 0 3em 1.5em 2em;
    margin: 0 -3em 0 -2em;
    strong {
      display: inline-block;
      margin-top: 0.25em;
      @include fz(90,80);
      font-weight: 500;
    }
    span {
      display: inline-block;
    }
  }
  .pic {
    max-width: 300px;
    margin: 0 auto;
  }
}
.sect2 {
  background: url('../img/sect2_bg.jpg') no-repeat 15% 50%;
  background-size: cover;
  .sect-title {
    @include fz(30px);
  }
  .pic {
    max-width: 300px;
    margin: 0 auto;
  }
}
.sect4 {
  .container {
    max-width: 400px;
  }
  .sect-title {
    margin-bottom: 2em;
  }
}
.sect5 {
  .container {
    max-width: 400px;
  }
  .sect-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0.25em;
    img {
      width: 7.5em;
    }
    span {
      font-size: 1.2em;
      margin-left: 0.5em;
      margin-top: 0.1em;
    }
  }
  .download-btns {
    display: flex;
    justify-content: center;
    margin: 0 -0.5em;
    a {
      display: block;
      flex: 1;
      margin: 0.5em;
      max-width: 12em;
    }
  }
  .pic {
    max-width: 300px;
    margin: 0 auto;
  }
}
.sect6 {
  padding-bottom: 3em;
  .txt {
    display: inline-block;
    text-align: left;
  }
  .sect-title {
    @include fz(72,32);
    line-height: (86.4 / 72);
    margin-bottom: 0.75em;
  }
  .pic {
    max-width: 300px;
    margin: 0 auto;
  }
}
.sect7 {
  .container {
    @include fz(14px);
  }
  .sect-title {
    @include fz(81,27);
  }
}
.sect8 {
  .sect-title {
    @include fz(81,38);
  }
}
.sect9 {
  background: url('../img/sect9_bg.jpg') no-repeat 50% 50%;
  background-size: cover;
  padding-bottom: 2em;
  .container {
    @include fz(20px);
  }
  .sect-title {
    @include fz(78,50);
    font-weight: 700;
    letter-spacing: 0.2em;
    margin-bottom: 0.5em;
  }
}
.box {
  margin: 0 auto 2.5em;
  .pic {
    margin: 0 auto 1em;
  }
  .title {
    span {
      display: inline-block;
    }
  }
}
.box1 {
  .pic {
    max-width: 200px;
  }
  .title {
    font-size: 1em;
    font-weight: 400;
  }
}
.box2 {
  max-width: 20em;
  .pic {
    margin-bottom: 2em;
    max-width: 240px;
  }
  .title {
    @include fz(28,21);
    font-weight: 400;
    line-height: 1.4;
    background: url('../img/sect3_title_bg.png') repeat-x;
    background-size: auto 100%;
    border-radius: 0.35em;
    max-width: 12em;
    margin: 0 auto 1.2em;
    padding: 0.5em 0.3em;
    position: relative;
    &::after {
      content: '';
      display: block;
      background: url('../img/sect3_title_caret.png') no-repeat 50% 0;
      background-size: contain;
      width: 1.8em;
      height: 0.9em;
      position: absolute;
      top: 100%;
      left: 50%;
      margin-top: -0.01em;
      margin-left: -0.9em;
      transform-origin: center top;
    }
  }
  .desc {
    background: url('../img/sect3_desc_bg.png') no-repeat;
    background-size: 100% 100%;
    padding: 1em 1.5em;
    text-align: justify;
    min-height: 8.8em;
  }
}
.box3 {
  .pic {
    position: relative;
    max-width: 50%;
    &-wrap {
      overflow: hidden;
    }
    &::before,
    &::after {
      content: '';
      display: block;
      background: #2e51a2;
      background: linear-gradient(to right,  #2e51a2 0%,#3bc7df 100%);
      position: absolute;
      top: 50%;
      height: 2px;
      transform: translateY(-50%);
      width: 100%;
    }
    &::before {
      right: 100%;
    }
    &::after {
      left: 100%;
    }
  }
  .title {
    color: $brand-secondary;
    font-weight: 400;
    line-height: 1.5;
    position: relative;
    small {
      display: block;
      @include fz(45,52);
      font-weight: 700;
    }
    &::after {
      content: '';
      display: block;
      background: url('../img/sect4_arrow.png') no-repeat;
      background-size: contain;
      width: 20 / 35 * 1em;
      height: 1em;
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translate(-50%,-50%);
      .col:last-child & {
        display: none;
      }
    }
  }
}
.box4 {
  background: url('../img/sect8_txt_bg.png') no-repeat;
  background-size: 100% 100%;
  padding: 1.2em 1.5em;
  margin-bottom: 5em;
  max-width: 25em;
  min-height: 21em;
  position: relative;
  .title {
    @include fz(57,38);
    font-weight: 700;
    line-height: 1.3;
    span {
      display: block;
    }
    &.c2 {
      color: $text-color2;
    }
  }
  .more {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 0.5em;
    @include fz(40,38);
  }
}

.list1 {
  text-align: left;
  line-height: 1.3;
  margin-bottom: 1em;
  li {
    position: relative;
    padding-left: 1.5em;
    margin-bottom: 0.5em;
    &::before {
      content: '';
      display: block;
      width: 20 / 38 + 0em;
      height: 20 / 38 + 0em;
      border-radius: 50%;
      border: 1px solid #fff;
      position: absolute;
      left: 0.15em;
      top: 0.65em;
      transform: translateY(-50%);
    }
  }
}
.list2 {
  text-align: left;
  line-height: 1.3;
  color: $text-color2;
  margin-bottom: 1em;
  li {
    position: relative;
    padding-left: 1.5em;
    margin-bottom: 0.5em;
    &::before {
      content: '';
      display: block;
      width: 28 / 38 + 0em;
      height: 28 / 38 + 0em;
      background: url('../img/sect8_plus.png') no-repeat;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 0.65em;
      transform: translateY(-50%);
    }
    small {
      color: #fff;
      @include fz(28,38);
    }
  }
}

.table1 {
  th, td {
    line-height: 1.4;
    span {
      display: inline-block;
    }
  }
  thead {
    td {
      display: none;
    }
    th {
      white-space: nowrap;
      padding: 0.5em 1em;
      @include fz(32,27);
    }
    tr:last-child {
      th {
        background: #1f5176;
        background: linear-gradient(to bottom,  #1f5176 0%,#183762 100%);
        &:first-child {
          border-radius: 0.35em 0 0 0;
        }
        &:last-child {
          background: rgb(245,211,161);
          background: linear-gradient(to bottom,  rgba(245,211,161,1) 0%,rgba(231,187,117,1) 68%,rgba(236,184,130,1) 100%);
          border-radius: 0 0.35em 0 0;
        }
      }
    }
  }
  tbody {
    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(83,230,234,0.13) 100%);
    th, td {
      padding: 1em;
    }
    th {
      text-align: left;
    }
    td {
      height: 4.5em;
    }
    tr + tr {
      td {
        border-top: 1px solid rgba(#f7f8f8,0.25);
      }
    }
    th, td:last-child {
      color: $brand-secondary;
    }
    small {
      @include fz(18,27);
    }
  }
}
.wrapper-sticky {
  border: 0;
  max-height: none;
}
.wrapper-sticky table td.skt-sticky-left, .wrapper-sticky table th.skt-sticky-left, .wrapper-sticky table td.skt-sticky-right, .wrapper-sticky table th.skt-sticky-right {
  outline: none;
  background-color: transparent;
}
.bg-group {
  background: url('../img/sect78_bg.jpg') no-repeat;
  background-size: 100% 100%;
}

@media (max-width: $screen-xs-max) {
  .wrapper-sticky table thead td.skt-sticky-left {
    background-color: #1A1753;
  }
  .wrapper-sticky table thead th.skt-sticky-left {
    background: #1f5176;
    background: linear-gradient(to bottom,  #1f5176 0%,#183762 100%);
  }
  .wrapper-sticky table thead td.skt-sticky-right {
    background-color: #070837;
  }
  .wrapper-sticky table thead th.skt-sticky-right {
    background: rgb(245,211,161);
    background: linear-gradient(to bottom,  rgba(245,211,161,1) 0%,rgba(231,187,117,1) 68%,rgba(236,184,130,1) 100%);
  }
  .wrapper-sticky table tbody th.skt-sticky-left {
    background-color: #131249;
  }
  .wrapper-sticky table tbody td.skt-sticky-right {
    background-color: #11254E;
  }
}
@media (min-width: $screen-sm-min) {
  .sect {
    @include fz(18px);
    padding: 4em 0 2em;
    &-title {
      font-size: 2em;
      margin-bottom: 1.5em;
    }
  }
  .sect0 {
    padding: 3em 0 0;
    .txt {
      font-size: 1.5em;
    }
    .pic {
      max-width: 380px;
    }
  }
  .sect2 {
    padding: 0;
    background-position: 50% 100%;
    .container {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }
    .sect-title {
      font-size: 2em;
      background: url('../img/sect2_txt_deco.png') no-repeat 60% 100%;
      background-size: 100% 2em;
      padding: 1.1em 2.5em 1.1em 4em;
      margin: 0 -2.5em 0 -4em;
    }
    .pic {
      width: 30%;
      max-width: none;
    }
    .txt {
      width: 70%;
      margin: 0 auto;
    }
  }
  .sect4 {
    .container {
      max-width: 1280px;
    }
  }
  .sect5 {
    .container {
      max-width: 640px;
    }
    .pic {
      max-width: 500px;
    }
  }
  .sect6 {
    padding: 0;
    .container {
      display: flex;
      align-items: center;
    }
    .txt {
      margin: 0 auto;
      padding-top: 7%;
    }
    .pic {
      max-width: 50%;
      padding-bottom: 8%;
    }
  }
  .sect9 {
    padding-bottom: 3em;
    .container {
      font-size: 1.5em;
    }
  }
  .box4 {
    padding: 1.8em 2em;
  }
  .table1 {
    thead {
      td {
        display: table-cell;
        padding: 0 1em 0.5em;
        img {
          max-height: 100px;
        }
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .sect0 {
    .container {
      display: flex;
      align-items: center;
    }
    .txt {
      text-align: left;
    }
    .title {
      margin-left: -3em;
      padding-left: 3em;
    }
    .pic {
      max-width: 45%;
    }
  }
  .sect2 {
    .sect-title {
      font-size: 2.5em;
    }
  }
  .sect5 {
    padding: 0;
    .container {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      max-width: 1280px;
    }
    .txt {
      margin: 0 auto;
    }
    .pic {
      max-width: none;
      width: 50%;
    }
  }
  .sect6 {
    .txt {
      @include fz(32,28);
    }
  }
  .sect8 {
    .col {
      display: flex;
      align-items: stretch;
    }
  }
  .box2 {
    .title {
      span {
        display: block;
      }
    }
  }
  .box3 {
    .title {
      font-size: 1.4em;
    }
  }
  .box4 {
    flex: 1;
  }
}
@media (min-width: $screen-lg-min) {
  .sect {
    padding: 5em 0 2.5em;
  }
  .sect0 {
    padding: 5% 0 0;
    .txt {
      font-size: 1.8em;
    }
  }
  .sect2 {
    padding: 0;
    .sect-title {
      font-size: 3em;
    }
  }
  .sect5,
  .sect6 {
    padding: 0;
  }
  .sect9 {
    padding: 5em 0 3em;
    .container {
      font-size: 1.75em;
    }
  }
  .box1 {
    .pic {
      width: 85%;
      max-width: 250px;
    }
  }
  .box2 {
    .title {
      span {
        display: inline-block;
      }
    }
  }
  .box4 {
    font-size: 1.1em;
    .title {
      span {
        display: inline-block;
      }
    }
  }
  .table1 {
    thead {
      td {
        img {
          max-height: 150px;
        }
      }
    }
  }
}
@media (min-width: $screen-xl-min) {
  .sect {
    @include fz(20px);
    &-title {
      @include fz(60,28);
    }
  }
  .box3 {
    .title {
      font-size: 1.75em;
    }
  }
  .box4 {
    font-size: 1.2em;
  }
}
