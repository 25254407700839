.header {
  position: relative;
  .site-title {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
    background: rgba($body-bg,0.8);
    box-shadow: 0 2px 4px rgba(#000,0.13);
    padding: 13px 60px 12px 18px;
    margin: 0;
    font-size: 1em;
    .logo {
      @include txt-hide;
      vertical-align: top;
      background-image: url(../img/logo.svg);
      background-size: contain;
      width: 384 * 0.4px;
      height: 100 * 0.4px;
    }
  }
  &-follow {
    text-align: center;
    li {
      padding: 1em;
    }
  }
}
.nav {
  position: fixed;
  z-index: 250;
  top: 65px;
  bottom: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
  transition: 0.3s;
  .nav-open & {
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &-open {
    overflow: hidden;
  }
  &-toggle {
    width: 65px;
    height: 65px;
    position: fixed;
    z-index: 250;
    top: 0;
    right: 0;
    .ic-bar {
      background: $brand-primary;
      width: 20px;
      height: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -1px;
      margin-left: -10px;
      transform-origin: center;
      transition: 0.3s;
      &:nth-of-type(1) {
        transform: translateY(-8px);
      }
      &:nth-of-type(2) {
        transform: translateY(8px);
      }
    }
    .nav-open & {
      .ic-bar {
        &:nth-of-type(1) {
          transform: rotate(45deg);
        }
        &:nth-of-type(2) {
          transform: rotate(-45deg);
        }
        &:nth-of-type(3) {
          display: none;
        }
      }
    }
  }
  &-content {
    padding: 1em 0;
    background: $brand-primary;
    box-shadow: 0 2px 4px rgba(#000,0.13);
  }
  .menu {
    text-align: center;
    padding: 1em 0;
    > li {
      > a {
        display: block;
        color: inherit;
        position: relative;
        padding: 0.5em;
      }
    }
  }
}

@media (min-width: $screen-xs-min) {
}
@media (min-width: $screen-sm-min) {
  .header {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    right: 0;
    background: rgba($body-bg,0.8);
    padding: 1em 0;
    transition: top 0.3s;
    @include clearfix;
    .header-fixed & {
      box-shadow: 0 2px 4px rgba(#000,0.13);
    }
    .header-hide.header-fixed & {
      top: -100px;
      transition-delay: 0.3s;
    }
    .site-title {
      position: static;
      background: none;
      box-shadow: none;
      float: left;
      padding: 6px 0;
      .logo {
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    &-follow {
      display: inline-block;
      vertical-align: middle;
      li {
        padding: 0.5em 0;
        margin-left: 1em;
      }
    }
  }
  .nav {
    position: static;
    float: right;
    &-toggle {
      display: none;
    }
    &-content {
      background: none;
      box-shadow: none;
      padding: 0;
    }
    .menu {
      display: inline-block;
      vertical-align: middle;
      padding: 0;
      @include fz(18px);
      li {
        display: inline-block;
        vertical-align: middle;
        a {
          padding: 0.6em;
          &:hover {
            color: $brand-secondary;
            font-weight: 500;
          }
        }
      }
    }
  }
}
@media (min-width: $screen-md-min) {
}
@media (min-width: $screen-lg-min) {
  .header {
    padding: 2em 0;
    .site-title {
      .logo {
        width: 384 * 0.6px;
        height: 100 * 0.6px;
      }
    }
    &-follow {
      li {
        margin-left: 2em;
      }
    }
  }
  .nav {
    .menu {
      @include fz(24px);
      > li {
        > a {
          padding: 0.6em 1.2em;
        }
      }
    }
  }
}
